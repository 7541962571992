import { v4 as uuidv4 } from 'uuid';

function makeSendAudioToBackend() {
	return async function sendAudioToBackend({ audio }) {
		const formData = new FormData();
		formData.append('audio', audio, 'recording.wav');
		formData.append('fileId', `${uuidv4()}.wav`);

		try {
			const response = await fetch('/upload-audio', {
				method: 'POST',
				body: formData,
			});
			const data = await response.json();
			return data;
		} catch (error) {
			console.log(error);
		}

		// navigate('/audio-list');
	};
}

export default makeSendAudioToBackend;
