import React from 'react';
import './hm.css';
import Header from '../../components/Header/Header';
import { useLocation } from 'react-router-dom';

function HM({ children }) {
	return (
		<div className={`hm greyBg`}>
			<header className='hmHeader'>
				<Header />
			</header>
			<main className='hmMain'>{children}</main>
		</div>
	);
}

export default HM;
