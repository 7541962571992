import React, { useEffect, useState } from 'react';
import HM from '../../layout/hm/HM';
import { useGetComparisonsQuery } from '../../services/audioApi';
import './chart.css';
import File from './File';

function Chart() {
	// const [reversed, setReversed] = useState([]);
	const { data, error, isLoading } = useGetComparisonsQuery();

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<HM>
			{data.body.map((e) => (
				<div className='chartItemContainer'>
					<div className='percentage'>{Math.floor(e.similarity)}%</div>
					<div className='files'>
						<File audio={e.audio1} cls='fileOne' />
						<File audio={e.audio2} cls='fileTwo' />
					</div>
				</div>
			))}
		</HM>
	);
}

export default Chart;
