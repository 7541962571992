import React from 'react';
import './options.css';
import { useDispatch } from 'react-redux';
import { setSearchField } from '../../features/searchSlice';

function Options({ search, setShopOptions, showOptions }) {
	const names = [
		'Bhavnesh Desai',
		'Sandhya Singh',
		'Ninad Zanje',
		'Sourabh Raghavendra',
	];

	const dispatch = useDispatch();

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		dispatch(setSearchField({ field: name, value }));
	};
	return (
		<div className='options'>
			<div className='optionsAudioFormField'>
				<label htmlFor='speaker'>Speaker:</label>
				<select
					id='speaker'
					name='speaker'
					value={search.speaker}
					onChange={handleInputChange}
				>
					<option value=''>Select speaker</option>
					{names.map((name, index) => (
						<option key={index} value={name}>
							{name}
						</option>
					))}
				</select>
			</div>
			<div className='optionsAudioFormField'>
				<label htmlFor='fileName'>Audio Name:</label>
				<input
					id='fileName'
					name='fileName'
					type='text'
					value={search.fileName}
					onChange={handleInputChange}
				/>
			</div>
			<div className='optionsAudioFormField'>
				<label htmlFor='environment'>Environment:</label>
				<input
					id='environment'
					name='environment'
					type='text'
					value={search.environment}
					onChange={handleInputChange}
				/>
			</div>
			<div className='optionsAudioFormField'>
				<label htmlFor='content'>Content:</label>
				<textarea
					id='content'
					name='content'
					value={search.content}
					onChange={handleInputChange}
				/>
			</div>
		</div>
	);
}

export default Options;
