import React, { useState } from 'react';
import './hmf.css';
import Header from '../../components/Header/Header';
import Options from '../../components/Options/Options';
import filterIcon from '../../assets/icons/filter.png';
import clickToExpand from '../../assets/icons/clickToExpand.png';
import reset from '../../assets/icons/reset.png';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearch } from '../../features/searchSlice';

function HMF({ children }) {
	const [showOptions, setShopOptions] = useState(false);
	const search = useSelector((state) => state.search);

	const dispatch = useDispatch();

	return (
		<div className={`hmf greyBg`}>
			<header className='hmfHeader'>
				<Header />
			</header>
			<main className='hmfMain'>{children}</main>
			<footer className='hmfFooter'>
				{showOptions ? (
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<img
							src={reset}
							alt='filter'
							onClick={() => dispatch(resetSearch())}
							style={{ height: '40px' }}
						/>
						<img
							src={clickToExpand}
							alt='filter'
							onClick={() => setShopOptions(!showOptions)}
							style={{ height: '40px' }}
						/>
					</div>
				) : (
					<img
						src={filterIcon}
						alt='filter'
						onClick={() => setShopOptions(!showOptions)}
						style={{ height: '40px' }}
					/>
				)}
			</footer>
			{showOptions ? (
				<Options
					search={search}
					// setSearch={setSearch}
					setShopOptions={setShopOptions}
					showOptions={showOptions}
				/>
			) : null}
		</div>
	);
}

export default HMF;
