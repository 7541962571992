import React from 'react';
import PlayAudio from '../../components/AudioPlayback/PlayAudio';
import playButton from '../../assets/icons/playAudio.png';

function File({ audio, cls }) {
	return (
		<div className={`${cls === 'fileOne' ? 'fileOne' : 'fileTwo'} file`}>
			<PlayAudio audioUrl={audio.url}>
				<img src={playButton} alt='' className='audioFileIcon' />
			</PlayAudio>
			{audio.fileName}
		</div>
	);
}

export default File;
