import React from 'react';
import HM from '../../layout/hm/HM';
import './rec.css';
import AudioRecorder from '../../components/AudioRecording/AudioRecorder';

function Recorder() {
	return (
		<HM>
			<AudioRecorder />
		</HM>
	);
}

export default Recorder;
