import React, { useEffect, useState } from 'react';
import toChoose from '../../assets/icons/toChoose.png';
import chosen from '../../assets/icons/chosen.png';
import { useDispatch, useSelector } from 'react-redux';
import { addToSelected, removeFromSelected } from '../../features/selectSlice';

function CompareButton({ data }) {
	const [selected, setSelected] = useState(false);
	// TODO move selected one step above to highlight entire file
	const handleOnClick = () => {
		setSelected(!selected);
		console.log(data);
	};

	const dispatch = useDispatch();

	const twoSelected = useSelector((state) => state.select.selected.length);

	const handleOnSelect = (data) => {
		dispatch(addToSelected(data));
	};

	const handleOnRemove = (data) => {
		dispatch(removeFromSelected(data));
	};

	useEffect(() => {
		if (twoSelected === 0) {
			setSelected(false);
		}
	}, [twoSelected]);

	return (
		<div className='actionButtonHolder' onClick={handleOnClick}>
			{selected ? (
				<img
					src={chosen}
					alt='expand details'
					className='audioFileIcon'
					onClick={() => handleOnRemove(data)}
				/>
			) : twoSelected < 2 ? (
				<img
					src={toChoose}
					alt='expand details'
					className='audioFileIcon'
					onClick={() => handleOnSelect(data)}
				/>
			) : null}
		</div>
	);
}

export default CompareButton;
