function makeSendMfccArrays() {
	return async function ({ mfcc1, mfcc2 }) {
		let mfccObject = { mfcc1, mfcc2 };
		try {
			const response = await fetch('/compare-audio', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(mfccObject),
			});
			const data = await response.json();
			console.log(data);
			return data;
		} catch (error) {
			console.log(error.message, 'while sending mfcc data to backend');
		}
	};
}

export default makeSendMfccArrays;
