import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSaveAudioEntryMutation } from '../../services/audioApi';
import makeSendAudioToBackend from '../AudioRecording/sendAudioToBackend';

function AudioForm({ resetRecording, audio }) {
	const names = [
		'Bhavnesh Desai',
		'Sandhya Singh',
		'Ninad Zanje',
		'Sourabh Raghavendra',
	];
	const [audioDetails, setAudioDetails] = useState({
		name: '',
		speaker: '',
		environment: '',
		content: '',
	});

	const [saveAudioEntry, { isLoading, isSuccess, isError, error }] =
		useSaveAudioEntryMutation();

	const navigate = useNavigate();

	let sendAudio = makeSendAudioToBackend();

	const handleChange = (event) => {
		const { name, value } = event.target;
		setAudioDetails((prevDetails) => ({
			...prevDetails,
			[name]: value,
		}));
	};

	const resetAudioDetails = () => {
		setAudioDetails({
			fileName: '',
			speaker: '',
			environment: '',
			content: '',
		});
	};

	const makeAudioEntry = async (audioFile) => {
		try {
			const result = await saveAudioEntry(audioFile).unwrap();
			console.log('Audio entry saved successfully:', result);
			console.log(isLoading);
			console.log(isSuccess);
			console.log(isError);
			console.log(error);
			console.log('from make audio entry');
		} catch (err) {
			console.error('Error saving audio entry:', err);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		let { url, fileId, loudness, mfcc } = await sendAudio({
			audio,
			resetRecording,
			navigate,
		});
		let audioFile = {
			fileName: audioDetails.fileName,
			speaker: audioDetails.speaker,
			environment: audioDetails.environment,
			content: audioDetails.content,
			url,
			fileId,
			loudness,
			mfcc,
		};
		let out = await makeAudioEntry(audioFile);
		console.log(out);
		resetAudioDetails();
		resetRecording();
		navigate('/audio-list');
	};

	return (
		<div className='audioFormContainer recForm'>
			<form onSubmit={handleSubmit} className='audioForm'>
				<p className='formTitle'>Enter information about the audio</p>
				<div className='audioFormField'>
					<label htmlFor='speaker'>Speaker:</label>
					<select
						id='speaker'
						name='speaker'
						value={audioDetails.speaker}
						onChange={handleChange}
					>
						<option value=''>Select speaker</option>
						{names.map((name, index) => (
							<option key={index} value={name}>
								{name}
							</option>
						))}
					</select>
				</div>
				<div className='audioFormField'>
					<label htmlFor='fileName'>Audio Name:</label>
					<input
						id='fileName'
						name='fileName'
						type='text'
						value={audioDetails.fileName}
						onChange={handleChange}
					/>
				</div>
				<div className='audioFormField'>
					<label htmlFor='environment'>Environment:</label>
					<input
						id='environment'
						name='environment'
						type='text'
						value={audioDetails.environment}
						onChange={handleChange}
					/>
				</div>
				<div className='audioFormField'>
					<label htmlFor='content'>Content:</label>
					<textarea
						id='content'
						name='content'
						value={audioDetails.content}
						onChange={handleChange}
					/>
				</div>
				<div className='formButtonContainer'>
					<button
						type='button'
						onClick={resetRecording}
						className='formButton actionSecondary'
					>
						Reset
					</button>
					<button type='submit' className='formButton actionPrimary'>
						Send
					</button>
				</div>
			</form>
		</div>
	);
}

export default AudioForm;
