import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import home from '../../assets/icons/home.png';
import './header.css';

function Header() {
	const navigate = useNavigate();

	const handleNavigate = (link) => {
		navigate(`/${link}`);
	};

	return (
		<div className='headerContainer'>
			<div className='variableHeader'>
				<img
					src={home}
					alt='new Recording'
					className='headerIcon'
					onClick={() => handleNavigate('')}
				/>
			</div>
		</div>
	);
}

export default Header;
