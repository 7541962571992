function filterData(data, search) {
	return data.filter((item) => {
		return Object.keys(search).every((key) => {
			if (!search[key]) return true; // if search key is empty, ignore it and return true
			if (key in item) {
				return item[key].toLowerCase().includes(search[key].toLowerCase());
			}
			return false; // if key doesn't exist in item, fail the match
		});
	});
}

export default filterData;
