import { useState } from 'react';
import OneAudio from './components/AudioFile/OneAudio';
import AudioRecorder from './components/AudioRecording/AudioRecorder';
import { Route, Routes } from 'react-router-dom';
import Homepage from './pages/homepage/Homepage';
import Recorder from './pages/recorder/Recorder';
import Compare from './pages/compare/Compare';
import AudioList from './pages/AudioList';
import Result from './pages/result/Result';
import Chart from './pages/chart/Chart';

function App() {
	const [compare, setCompare] = useState(false);
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route path='/recorder' element={<Recorder />} />
				<Route path='/compare' element={<Compare />} />
				<Route path='/audio-list' element={<AudioList />} />
				<Route path='/result' element={<Result />} />
				<Route path='/chart' element={<Chart />} />
				<Route path='/*' element={<Homepage />} />
			</Routes>
			{/* <h1 onClick={() => setCompare(!compare)}>Sourabh</h1>
			<AudioRecorder />
			<OneAudio compare={compare} /> */}
		</div>
	);
}

export default App;
