// src/features/search/searchSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	selected: [],
	result: '0',
};

export const selectSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		addToSelected: (state, action) => {
			if (state.selected.length < 2) {
				state.selected.push(action.payload);
			}
		},
		removeFromSelected: (state, action) => {
			if (
				state.selected.some(
					(element) =>
						element.url === action.payload.url &&
						element.name === action.payload.name
				)
			) {
				const index = state.selected.findIndex(
					(element) =>
						element.url === action.payload.url &&
						element.name === action.payload.name
				);
				if (index !== -1) {
					state.selected.splice(index, 1); // Removes the element at position `index`
				}
			}
		},
		clearSelectionArray: (state, action) => {
			state.selected = [];
		},
		setResult: (state, action) => {
			state.result = action.payload;
		},
	},
});

export const {
	addToSelected,
	removeFromSelected,
	clearSelectionArray,
	setResult,
} = selectSlice.actions;

export default selectSlice.reducer;
