import React from 'react';
import { useNavigate } from 'react-router-dom';

function BottomButtons() {
	const navigate = useNavigate();

	return (
		<div
			style={{
				display: 'flex',
				width: '70%',
				margin: '0 auto',
				position: 'absolute',
				bottom: '20px',
				left: '50%',
				transform: 'translateX(-50%)',
				justifyContent: 'space-between',
			}}
		>
			{' '}
			<button
				className='formButton actionSecondary'
				onClick={() => navigate('/chart')}
			>
				View Chart
			</button>
			<button
				className='formButton actionSecondary'
				onClick={() => navigate('/compare')}
			>
				Compare another
			</button>
		</div>
	);
}

export default BottomButtons;
