import React from 'react';

function AudioDetails({ audioData }) {
	return (
		<div className='audioDetailHolder'>
			<p className='audioDetailsSpecific'>
				<span className='audioDetailsHeader'>Name:</span>
				{'   '}
				{audioData.audioName}
			</p>
			<p className='audioDetailsSpecific'>
				<span className='audioDetailsHeader'>Speaker:</span>
				{'   '}
				{audioData.speaker}
			</p>
			<p className='audioDetailsSpecific'>
				<span className='audioDetailsHeader'>Environment:</span>
				{'   '}
				{audioData.environment}
			</p>
			<p className='audioDetailsSpecific'>
				<span className='audioDetailsHeader'>Level:</span>
				{'   '}
				{audioData.loudness}
			</p>
			<p className='audioDetailsSpecific'>
				<span className='audioDetailsHeader'>Content:</span>
				{'   '}
				{audioData.content}
			</p>
		</div>
	);
}

export default AudioDetails;
