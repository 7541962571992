import PlayAudio from '../AudioPlayback/PlayAudio';
import playRec from '../../assets/icons/playRec.png';
import AudioForm from '../audioForm/AudioForm';

function SaveAudioForm({ audioUrl, resetRecording, audio }) {
	console.log(audioUrl, 'audioUrl from test component');
	return (
		<div className='saveAudioComponent recForm'>
			<div className='recAudioPlaybackHolder'>
				<PlayAudio audioUrl={audioUrl}>
					<img src={playRec} alt='' className='recAudioPlayback' />
					<p className='recText'>Tap to listen</p>
				</PlayAudio>
			</div>
			<AudioForm
				// sendAudioToBackend={sendAudioToBackend}
				resetRecording={resetRecording}
				audio={audio}
			/>
		</div>
	);
}

export default SaveAudioForm;
