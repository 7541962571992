import React from 'react';
import HM from '../../layout/hm/HM';
import './result.css';
import OneAudio from '../../components/AudioFile/OneAudio';
import { useSelector } from 'react-redux';
import BottomButtons from './BottomButtons';

function Result() {
	const selected = useSelector((state) => state.select.selected);
	const result = useSelector((state) => state.select.result);
	return (
		<HM>
			<p className='formTitle' style={{ marginBottom: '8x' }}>
				Result :
			</p>
			<div className='resultFilesContainer'>
				{selected.map((e) => (
					<OneAudio audioData={e} />
				))}
			</div>
			<div className='resultNumber'>{Math.floor(result)}%</div>
			<BottomButtons />
		</HM>
	);
}

export default Result;
