// src/services/audioApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define our single API slice object
const audioApi = createApi({
	reducerPath: 'audioApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'https://www.ini-chat.in' }),
	tagTypes: ['Audio'],
	endpoints: (builder) => ({
		getAudioEntries: builder.query({
			query: () => '/get-all-audios',
			providesTags: ['Audio'],
		}),
		saveAudioEntry: builder.mutation({
			query: (newAudio) => ({
				url: '/save-audio-entry',
				method: 'POST',
				body: newAudio,
			}),
			invalidatesTags: ['Audio'],
		}),
		getComparisons: builder.query({
			query: () => '/get-comparisons',
			providesTags: ['Comparison'], // This endpoint provides 'Comparison' tag
		}),
		saveComparison: builder.mutation({
			query: (comparisonData) => ({
				url: '/register-comparison',
				method: 'POST',
				body: comparisonData,
			}),
			invalidatesTags: ['Comparison'], // Invalidate 'Comparison' tag on success
		}),
	}),
});

export const {
	useGetAudioEntriesQuery,
	useSaveAudioEntryMutation,
	useGetComparisonsQuery,
	useSaveComparisonMutation,
} = audioApi;
export default audioApi;
