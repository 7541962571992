import React from 'react';
import CleanScreen from '../../layout/cleanScreen/CleanScreen';
import { Link } from 'react-router-dom';
import './homepage.css';

function Homepage() {
	return (
		<CleanScreen>
			<div className='homePage'>
				<nav className='homePageNav'>
					<ul className='navList'>
						<li className='navItemContainer'>
							<Link to='/recorder' className='navItemLink'>
								• Recorder
							</Link>
						</li>
						<li className='navItemContainer'>
							<Link to='/audio-list' className='navItemLink'>
								• Audios
							</Link>
						</li>
						<li className='navItemContainer'>
							<Link to='/compare' className='navItemLink'>
								• Compare
							</Link>
						</li>
						<li className='navItemContainer'>
							<Link to='/chart' className='navItemLink'>
								• Chart
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</CleanScreen>
	);
}

export default Homepage;
