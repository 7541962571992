import React, { useMemo } from 'react';
import HMF from '../layout/hmf/HMF';
import OneAudio from '../components/AudioFile/OneAudio';
import { useSelector } from 'react-redux';
import filterData from '../utility/filterData';
import { useGetAudioEntriesQuery } from '../services/audioApi';

function AudioList() {
	const { data, error, isLoading } = useGetAudioEntriesQuery();

	const search = useSelector((state) => state.search);

	const filteredData = useMemo(() => {
		return data?.body ? filterData(data.body, search).reverse() : [];
	}, [data?.body, search]);

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	return (
		<HMF>
			{filteredData.map((a, index) => (
				<OneAudio key={index} audioData={a} />
			))}
		</HMF>
	);
}

export default AudioList;
