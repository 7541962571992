import HMF from '../../layout/hmf/HMF';
import OneAudio from '../../components/AudioFile/OneAudio';
import { useDispatch, useSelector } from 'react-redux';
import filterData from '../../utility/filterData';
import './compare.css';
import { clearSelectionArray, setResult } from '../../features/selectSlice';
import { useNavigate } from 'react-router-dom';
import {
	useGetAudioEntriesQuery,
	useSaveComparisonMutation,
} from '../../services/audioApi';
import { useMemo } from 'react';
import makeSendMfccArrays from './sendMfccArrays';

function AudioList() {
	const search = useSelector((state) => state.search);
	const selected = useSelector((state) => state.select.selected);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sendMfcc = makeSendMfccArrays();
	const [saveComparison, { isError, error: savingError }] =
		useSaveComparisonMutation();

	const handleComparison = async (selectedArray) => {
		let mfcc1 = selectedArray[0].mfcc;
		let mfcc2 = selectedArray[1].mfcc;
		let { output } = await sendMfcc({ mfcc1, mfcc2 });
		dispatch(setResult(output.result));
		await saveComparison({
			audio1: selectedArray[0],
			audio2: selectedArray[1],
			similarity: output.result,
		}).unwrap();
		console.log(savingError ? savingError : 'great');
		navigate('/result');
	};

	const { data, error, isLoading } = useGetAudioEntriesQuery();

	const filteredData = useMemo(() => {
		return data?.body ? filterData(data.body, search).reverse() : [];
	}, [data?.body, search]);

	if (isLoading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
	if (isError) {
		alert('something went wrong while saving comparison to database');
	}

	return (
		<HMF>
			{selected.length === 2 ? <div className='overlay'></div> : null}
			{filteredData.map((a, index) => (
				<OneAudio key={index} audioData={a} compare={true} />
			))}
			{selected.length === 2 ? (
				<div className='compareModal'>
					<p className='formTitle'>Compare Audios</p>

					{selected.map((e) => (
						<OneAudio audioData={e} />
					))}
					<div
						style={{
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<button
							onClick={() => dispatch(clearSelectionArray())}
							className='formButton actionSecondary'
						>
							Clear Selection
						</button>
						<button
							className='formButton actionPrimary'
							onClick={() => handleComparison(selected)}
						>
							Compare
						</button>
					</div>
				</div>
			) : null}
		</HMF>
	);
}

export default AudioList;
