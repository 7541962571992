import React, { useState, useEffect, useRef } from 'react';
import SaveAudioForm from './SaveAudioForm';
import startRec from '../../assets/icons/startRec.png';
import stopRec from '../../assets/icons/stopRec.png';
import RecButtonComponent from './RecButtonComponent';
import { useNavigate } from 'react-router-dom';

const AudioRecorder = () => {
	const [isRecording, setIsRecording] = useState(false);
	const [audioUrl, setAudioUrl] = useState(null);
	const [audio, setAudio] = useState(null);
	const [isRecodingStopped, setIsRecordingStopped] = useState(false);
	const mediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);

	const navigate = useNavigate();

	const startRecording = () => {
		audioChunksRef.current = [];
		mediaRecorderRef.current.start();
		setIsRecording(true);
	};

	const stopRecording = () => {
		mediaRecorderRef.current.stop();
		setIsRecording(false);
	};

	const resetRecording = () => {
		setIsRecordingStopped(false);
		setAudioUrl(null);
		setAudio(null);
	};

	useEffect(() => {
		navigator.mediaDevices
			.getUserMedia({ audio: true })
			.then((stream) => {
				mediaRecorderRef.current = new MediaRecorder(stream);

				mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
					audioChunksRef.current.push(event.data);
				});

				mediaRecorderRef.current.addEventListener('stop', () => {
					const audioBlob = new Blob(audioChunksRef.current, {
						type: 'audio/wav',
					});
					const audioUrl = URL.createObjectURL(audioBlob);
					setAudioUrl(audioUrl);
					setAudio(audioBlob);
					audioChunksRef.current = [];
					setIsRecordingStopped(true);
				});
			})
			.catch((error) => {
				console.error('Error accessing microphone', error);
			});

		return () => {
			if (mediaRecorderRef.current) {
				mediaRecorderRef.current.stream
					.getTracks()
					.forEach((track) => track.stop());
			}
		};
	}, []);

	return (
		<div className='recPage'>
			{!isRecodingStopped ? (
				<RecButtonComponent
					isRecording={isRecording}
					startRecording={startRecording}
					startRec={startRec}
					stopRecording={stopRecording}
					stopRec={stopRec}
				/>
			) : (
				<SaveAudioForm
					audioUrl={audioUrl}
					resetRecording={resetRecording}
					// sendAudioToBackend={sendAudio}
					audio={audio}
				/>
			)}
		</div>
	);
};

export default AudioRecorder;
