import React from 'react';
import './cleanScreen.css';

function CleanScreen({ children }) {
	return (
		<div>
			<div className='cleanScreen'>
				{children}
				<footer className='cleanFooter'>
					<a
						href='http://www.480ps.com'
						target='_blank'
						rel='noopener noreferrer'
					>
						Built care by 480PS
					</a>
				</footer>
			</div>
		</div>
	);
}

export default CleanScreen;
