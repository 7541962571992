import React from 'react';

function RecButtonComponent({
	isRecording,
	startRecording,
	startRec,
	stopRecording,
	stopRec,
}) {
	return (
		<div className='recButtonContainer'>
			{!isRecording ? (
				<div
					onClick={startRecording}
					disabled={isRecording}
					className='recStateButton'
				>
					<img src={startRec} alt='' srcset='' className='recButton' />
					<p className='recText'>Tap to record</p>
				</div>
			) : null}
			{isRecording ? (
				<div
					onClick={stopRecording}
					disabled={!isRecording}
					className='recStateButton'
				>
					<img src={stopRec} alt='' srcset='' className='recButton' />
					<p className='recText'>Tap to stop</p>
				</div>
			) : null}
		</div>
	);
}

export default RecButtonComponent;
