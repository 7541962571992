// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from '../features/searchSlice';
import selectSlice from '../features/selectSlice';
import audioApi from '../services/audioApi';

export const store = configureStore({
	reducer: {
		search: searchReducer,
		select: selectSlice,
		[audioApi.reducerPath]: audioApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(audioApi.middleware),
});
