import React, { useRef } from 'react';

function PlayAudio({ audioUrl, children }) {
	const audioRef = useRef(null);

	const playAudio = () => {
		console.log('clicked play audio from playAudio');
		console.log(audioRef.current);
		if (audioRef.current) {
			audioRef.current.play();
		}
	};

	return (
		<>
			{audioUrl && <audio ref={audioRef} src={audioUrl} />}
			<div onClick={playAudio}>
				{children ? children : <button>Click me</button>}
			</div>
		</>
	);
}

export default PlayAudio;
