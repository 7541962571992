import React, { useState } from 'react';
import './audioFile.css';
import PlayAudio from '../AudioPlayback/PlayAudio';
import playButton from '../../assets/icons/playAudio.png';
import expandButton from '../../assets/icons/clickToExpand.png';
import collapseButton from '../../assets/icons/clickToCollapse.png';
import AudioDetails from './AudioDetails';
import CompareButton from './CompareButton';

function OneAudio({ audioData, compare }) {
	let data = audioData
		? audioData
		: {
				url: '',
				Name: '',
				Speaker: '',
				Environment: ' ',
				Level: '',
				Content: '',
		  };

	const [open, setOpen] = useState(false);

	const toggleShowDetails = () => {
		setOpen(!open);
	};

	return (
		<div className='oneAudio'>
			<div className='audioFile'>
				<div className='audioFileGroup'>
					<div className='playButtonHolder'>
						<PlayAudio audioUrl={data.url}>
							<img src={playButton} alt='' className='audioFileIcon' />
						</PlayAudio>
					</div>
					<div className='audioFileName'>
						<span>{data.fileName ? data.fileName : 'A random sound'}</span>
					</div>
				</div>
				<div className='audioFileActionButton'>
					{!compare ? (
						open ? (
							<div className='actionButtonHolder' onClick={toggleShowDetails}>
								<img
									src={collapseButton}
									alt='collapse details'
									className='audioFileIcon'
								/>
							</div>
						) : (
							<div className='actionButtonHolder' onClick={toggleShowDetails}>
								<img
									src={expandButton}
									alt='expand details'
									className='audioFileIcon'
								/>
							</div>
						)
					) : (
						<CompareButton data={data} />
					)}
				</div>
			</div>
			{open && !compare ? (
				<div className='audioDetails'>
					<AudioDetails audioData={audioData} />
				</div>
			) : null}
		</div>
	);
}

export default OneAudio;
