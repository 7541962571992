// src/features/search/searchSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	fileName: '',
	speaker: '',
	content: '',
	similarity: '',
};

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchField: (state, action) => {
			const { field, value } = action.payload;
			state[field] = value;
		},
		resetSearch: (state) => {
			Object.keys(state).forEach((key) => {
				state[key] = '';
			});
		},
	},
});

export const { setSearchField, resetSearch } = searchSlice.actions;

export default searchSlice.reducer;
